const enableDebug = false;

var anim = {
  initElements: function() {

        if (jQuery('.reveal').length > 0){    
          gsap.utils.toArray(".reveal").forEach(function(elem) {
            hide(elem); 
          });
        }


        if (jQuery('svg.animateIcons').length > 0){ 

          var singleIcon = gsap.utils.toArray("svg.animateIcons");
            singleIcon.forEach((section,i) => {
    
              section._path = section.querySelectorAll("*");
              TweenMax.set(section._path, {drawSVG:"0%"});
    
            });
        }

    
  },
  animateElements: function() {

        if (jQuery('.reveal').length > 0){    
          gsap.utils.toArray(".reveal").forEach(function(elem) {

            ScrollTrigger.create({
              trigger: elem, 
              onEnter: function() { animateFrom(elem) }, 
              onEnterBack: function() { animateFrom(elem, -1) },
              onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
            });
          });
        }


        if (jQuery('svg.animateIcons').length > 0){

          const singleLetters = gsap.utils.toArray("svg.animateIcons");
          singleLetters.forEach((section) => {
            
            section._tl = gsap.timeline({paused:true});
            section._path = section.querySelectorAll("*");
            section._tl.fromTo(section._path, 1.5, {drawSVG:"0% 0%"}, {drawSVG:"100% 0%", ease:Power2.inOut})


            ScrollTrigger.create({ 
              trigger: section,
              start: "top center", 
              onEnter: () => { 
                section._tl.timeScale(1).play();
              },
              onLeaveBack: () => {
                section._tl.timeScale(2).reverse();
              }
            });



              
          });
        }


  }
}

jQuery(function(){


  if (isIE()){
    jQuery("body").addClass("isIE");
  }


  
  if(!enableDebug){

        if(!isIE() || (isIE() && ieVersion() > 9))
          gsap.registerPlugin(ScrollTrigger);
    
  }


  if (jQuery(window).scrollTop() > 80){
    jQuery('.site-header').addClass('site-header--fixed');
    jQuery('.site-progress-container').addClass('scroll');
  }
      


});


jQuery(window).on("load", function () {

      // loader
      if(!enableDebug){

        if(!isIE() || (isIE() && ieVersion() != 9)){ 


            jQuery('.site-loader').delay(500).fadeOut(500, function() {
              jQuery('.site-loader').removeClass('init');
              anim.initElements();
              anim.animateElements();
            });
            
          


        }else{

          jQuery('.site-loader').delay(500).fadeOut(500, function() {
            jQuery('.site-loader').removeClass('init');
          });
        

        }
        

      }else{

        jQuery('.site-loader').delay(500).fadeOut(500, function() {
          jQuery('.site-loader').removeClass('init');
        });
      

      }




      //Replace all SVG images with inline SVG
      jQuery('img[src$=".svg"]').each(function(index, element) {
        var $img = jQuery(this),
        imgURL = $img.attr('src'),
        imgClass= ($img.attr('class') !='') ? $img.attr('class') : '';

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');
            $svg = $svg.removeAttr('id');
            if(imgClass!='') $svg = $svg.addClass(imgClass);

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');
    });


      jQuery('.site-search__form').on("submit", function(e) { 
          var s = jQuery( this ).find('input[name="s"]').val(jQuery.trim(jQuery( this ).find('input[name="s"]').val()));
          if (!s.val()) { 
              e.preventDefault(); 
              jQuery( this ).find('input[name="s"]').focus();
          }
      });

      
      jQuery('.site-header a.site-header__search').on("click tap", function(e) {
        e.preventDefault();
        jQuery('.site-search').addClass('opened');
        setTimeout(function(){ 
          jQuery('.site-search .site-search__wrapper,.site-search  .site-search__button').fadeIn(500);
        }, 600);

      });

      jQuery('.site-search .site-search__button').on("click tap", function(e) {
        e.preventDefault();
        jQuery('.site-search .site-search__wrapper,.site-search  .site-search__button').fadeOut(500);
        setTimeout(function(){ 
          jQuery('.site-search').removeClass('opened');
        }, 600);

        

      });

      
    

      if (jQuery('img.aligncenter').length > 0) {
        jQuery('img.aligncenter').parent('p').css('textAlign','center');
      }


        // custom select
        jQuery('select.custom-select').each(function () {
            var $this = jQuery(this),
                numberOfOptions = jQuery(this).children('option').length;
            var selectID = jQuery(this).attr('id');

            $this.addClass('select-hidden');
            $this.wrap('<div class="select"></div>');
            $this.after('<div class="select-styled"></div>');

            var $styledSelect = $this.next('div.select-styled');
            $styledSelect.text($this.children('option').eq(0).text());

            var $list = jQuery('<ul />', {
                'class': 'select-options'
            }).insertAfter($styledSelect);

            for (var i = 0; i < numberOfOptions; i++) {
                jQuery('<li />', {
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val()
                }).appendTo($list);
            }

            var $listItems = $list.children('li');


            $styledSelect.on('click tap', function (e) {
                e.stopPropagation();
                jQuery('div.select-styled.active').not(this).each(function () {
                    jQuery(this).removeClass('active').next('ul.select-options').hide();
                });
                jQuery(this).toggleClass('active').next('ul.select-options').toggle();
            });

            $listItems.on('click tap', function (e) {
                e.stopPropagation();
                jQuery(this).parent('ul.select-options').find('li').removeClass("selected").removeAttr('class');
                $styledSelect.text(jQuery(this).text()).removeClass('active');
                jQuery(this).addClass('selected');
                $this.val(jQuery(this).attr('rel'));
                $list.hide();
                var valSelected = $this.val();
                //console.log($this.val());

                if (selectID == 'sort-projects') {
                    filterList(valSelected);
                }
            });

            jQuery(document).on('click tap', function () {
                $styledSelect.removeClass('active');
                $list.hide();
            });

        });





  
      //slick slider
      if (jQuery('.slick-slider').length > 0) {

        jQuery('.slick-slider').each(function (index) {

                var currentSlider = jQuery(this);
                var setDots = (currentSlider.hasClass('hasDots')) ? true : false;;
                var setArrows = (currentSlider.hasClass('hasArrows')) ? true : false;
                var setAutoplay = (currentSlider.hasClass('autoplay')) ? true : false;
                var fadeIn = (currentSlider.hasClass('fadeIn') || currentSlider.hasClass('homeSlider')) ? true : false;
                var aheight = (currentSlider.hasClass('adaptiveHeight')) ? true : false;


                  if(currentSlider.hasClass('multiple')){

                    if(currentSlider.hasClass('col4')){

                      currentSlider.slick({
                        autoplay: setAutoplay,
                        autoplaySpeed: 4000,
                        arrows: setArrows,
                        dots: setDots,
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        adaptiveHeight: aheight,
                        infinite: true,
                        fade: fadeIn,
                        cssEase: 'linear',
                        responsive: [{
                    
                          breakpoint: 1170,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                          }
                    
                        },{
                    
                          breakpoint: 970,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          }
                    
                        },{
                    
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                    
                        }, {
                    
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                    
                        }]
                    });

                  }else if(currentSlider.hasClass('col3')){

                        currentSlider.slick({
                          autoplay: setAutoplay,
                          autoplaySpeed: 4000,
                          arrows: setArrows,
                          dots: setDots,
                          slidesToShow: 3,
                          slidesToScroll: 3,
                          adaptiveHeight: aheight,
                          infinite: true,
                          fade: fadeIn,
                          cssEase: 'linear',
                          responsive: [{
                      
                            breakpoint: 970,
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                            }
                      
                          },{
                      
                            breakpoint: 768,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                            }
                      
                          }, {
                      
                            breakpoint: 480,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                            }
                      
                          }]
                      });

                    }else{
                        currentSlider.slick({
                          autoplay: setAutoplay,
                          autoplaySpeed: 4000,
                          arrows: setArrows,
                          dots: setDots,
                          slidesToShow: 2,
                          slidesToScroll: 2,
                          adaptiveHeight: aheight,
                          infinite: true,
                          fade: fadeIn,
                          cssEase: 'linear',
                          responsive: [{
                      
                            breakpoint: 970,
                            settings: {
                              slidesToShow: 2,
                              slidesToScroll: 2,
                            }
                      
                          },{
                      
                            breakpoint: 768,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                            }
                      
                          }, {
                      
                            breakpoint: 480,
                            settings: {
                              slidesToShow: 1,
                              slidesToScroll: 1,
                            }
                      
                          }]
                      });
                    }
                
    
                  }else{
                    currentSlider.slick({
                      autoplay: setAutoplay,
                      autoplaySpeed: 4000,
                      arrows: setArrows,
                      dots: setDots,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      fade: fadeIn,
                      adaptiveHeight: aheight,
                      cssEase: 'linear',
                    });
                  }
               

        });
      }





      jQuery( ".site-header:not('.mobile') .site-navigation__menu ul.menu li.menu-item-has-children ul.sub-menu" ).on({
          mouseenter: function() {
            jQuery( this ).parent("li.menu-item-has-children").addClass( "active" );
          },
          mouseleave: function() {
            jQuery( this ).parent("li.menu-item-has-children").removeClass( "active" );
          }
      })


      //load more in projects page
      if (jQuery('.site-recent-projects.list .one article.site-recent-project').length > 0) {

          var counterAnimation = false,
              objectPositionTop = jQuery('.counter').first().offset().top;
          var icone = [];


          jQuery(window).on('scroll', function () {

              var currentPosition = jQuery(window).scrollTop();
              var condition = false;

              if (is_touch_device())
                  condition = jQuery(this).scrollTop() > objectPositionTop && counterAnimation === false;
              else
                  condition = jQuery(this).scrollTop() > (objectPositionTop - jQuery(window).height()) && counterAnimation === false;

              if (condition) {
                  counterAnimation = true;

                  jQuery('.count').each(function (index) {


                      jQuery(this).prop('Counter', 0).animate({
                          Counter: jQuery(this).parent().find('.realNumber').text()
                      }, {
                          duration: 8000,
                          easing: 'swing',
                          step: function (now) {
                              jQuery(this).text(Math.ceil(now));
                          }
                      });


                  });


              }

          });

          jQuery('.site-recent-projects.list article.segna').slice(0, 9).removeClass('hidden');
          if (jQuery('.site-recent-projects.list article.segna').length < 10) jQuery(".site-recent-projects.list a.loadMore").addClass('hidden');

          //filter projects by data-category attribute
          jQuery('select#sort-projects').change(function () {
              var filter = jQuery(this).val()
              filterList(filter);
          });




          jQuery(".site-recent-projects.list a.loadMore").on('click tap', function (e) {
              e.preventDefault();
              jQuery(".site-recent-projects.list article.segna:hidden").slice(0, 9).removeClass('hidden').slideDown();

              if (jQuery(".site-recent-projects.list article.segna:hidden").length == 0) {
                  jQuery(".site-recent-projects.list a.loadMore").addClass('hidden');
              }

          });



      }
      

      //hamburger menu animation
      jQuery('.site-header__menu__button').on("click tap", function (e) {
        e.preventDefault();
        jQuery('.site-overlay').css('width', '100%');
       });

       jQuery('.site-overlay .close').on("click tap", function (e) {
            e.preventDefault();
            jQuery('.site-overlay').css('width', '0%');
        });


      //mobile menu
      if(Modernizr.mq('(min-width: 1170px)'))
          jQuery('.site-header').removeClass('mobile');
        else
          jQuery('.site-header').addClass('mobile');
      

      var cachedWidth = jQuery(window).width();


      if (jQuery('.site-top.page404').length > 0) {
        jQuery('.site-top.page404').css('minHeight', jQuery(window).height());
        verticallyCentered('page404');
      }

      jQuery(window).on('orientationchange, resize',function() {

        var newWidth = jQuery(window).width();
  
        if(Modernizr.mq('(min-width: 1170px)'))
          jQuery('.site-header').removeClass('mobile');
        else
          jQuery('.site-header').addClass('mobile');
                             
          
          resetHeaderNavigation();

          jQuery('.site-top.page404').css('minHeight', jQuery(window).height());
          verticallyCentered('page404');

      });


      jQuery(window).scroll(function(){



        if (jQuery(this).scrollTop() > 100)
          jQuery('.scrollToTop').fadeIn();
        else 
          jQuery('.scrollToTop').fadeOut();
        

        //fixed header
        if (jQuery(this).scrollTop() > 80) {
          jQuery('.site-header').addClass('site-header--fixed');
          jQuery('.site-progress-container').addClass('scroll');
          resetHeaderNavigation();
        } else {
          jQuery('.site-header').removeClass('site-header--fixed');
          jQuery('.site-progress-container').removeClass('scroll');
        }

      });

      jQuery(".scrollToTop").on("click tap", function(e) {
        e.preventDefault();
        jQuery("html, body").animate({ scrollTop: 0 }, 500);
        return false;
      });

      jQuery("a[href^='#']:not(.loadMore):not(.scrollToTop)").not('[href="#"]').on("click tap", function(e) {
          e.preventDefault();
          if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = jQuery(this.hash);
            target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              jQuery('html,body').animate({
                scrollTop: target.offset().top
              }, 500);
              return false;
            }
          }
      });
   
})


function is_touch_device() {
  return 'ontouchstart' in window        // works on most browsers
      || navigator.maxTouchPoints;       // works on IE10/11 and Surface
};


function animateFrom(elem, direction) {
  direction = direction | 1;
  
  var x = 0,
      y = direction * 100;
  if(elem.classList.contains("reveal_fromLeft")) {
    x = -100;
    y = 0;
  } else if(elem.classList.contains("reveal_fromRight")) {
    x = 100;
    y = 0;
  }
  gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
    duration: 2, 
    x: 0,
    y: 0, 
    autoAlpha: 1, 
    ease: "expo", 
    overwrite: "auto"
  });
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0});
}


function isIE(){
  var ua = window.navigator.userAgent;
  
  var is_ie = false;
  
  if(ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1)
    is_ie = true;

  return is_ie;
}

function ieVersion(){
    var ua = window.navigator.userAgent;
    var version = 0;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    return version;
}


function resetHeaderNavigation(){
  jQuery('.site-header').removeClass('openedMenu');
  jQuery('.site-header .site-navigation__menu').removeAttr("style");
  jQuery('.site-header .site-navigation__menu__button .btn').removeClass('active').addClass('not-active');
  jQuery('.site-header .site-navigation__menu ul.menu li.menu-item-has-children').removeClass( "opened" );
}


function createCookie(name, value, days) {
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}




function convertTextAlign(s){
  var styles = s.prop('style'),value='';
  if(styles.textAlign!=''){

    if(styles.textAlign=='center')
      value='alignCenter';
    else if(styles.textAlign=='right')
      value='alignRight';
  }
    
  return value;
}



function verticallyCentered(target) {

    if (target == 'page404') {
        var wwHeight = jQuery(window).height();
        var boxHeight = jQuery('.site-top.' + target + ' .verticallyCentered').outerHeight();
        var logoHeight = jQuery('.site-header__logo').outerHeight();

        if (wwHeight > Math.floor((boxHeight + logoHeight))) {
            jQuery('.site-top.' + target + ' .verticallyCentered').css('marginTop', Math.floor(((wwHeight - boxHeight - logoHeight) / 2)+logoHeight) + 'px');
            jQuery('.site-top.' + target + ' .verticallyCentered').css('marginBottom', Math.floor((wwHeight - boxHeight - logoHeight) / 2) + 'px');

        } else {
            jQuery('.site-top.' + target + ' .verticallyCentered').css('marginTop', logoHeight + 60);
            jQuery('.site-top.' + target + ' .verticallyCentered').css('marginBottom', 150);

        }
    }

    return false;

}


//Projects filter function
function filterList(value) {
  var list = jQuery(".site-recent-projects.list .one article");
  jQuery(list).addClass('hidden');

  if (value == "tutti") {


    jQuery(".site-recent-projects.list .one").find("article").each(function (i) {
          if (!jQuery(this).hasClass('segna')) jQuery(this).addClass('segna');
      });

      jQuery('.site-recent-projects.list article.segna').slice(0, 9).removeClass('hidden');

      if (jQuery('.site-recent-projects.list article.segna').length < 10)
          jQuery(".site-recent-projects.list a.loadMore").addClass('hidden');

      else
          jQuery(".site-recent-projects.list a.loadMore").removeClass('hidden');


  } else {


      jQuery(".site-recent-projects.list .one").find("article").each(function (i) {
          jQuery(this).removeClass('segna');
      });


      jQuery(".site-recent-projects.list .one").find("article[data-category*=" + value + "]").each(function (i) {
          jQuery(this).addClass('segna');
      });

      jQuery('.site-recent-projects.list article.segna').slice(0, 9).removeClass('hidden');

      if (jQuery('.site-recent-projects.list article.segna').length < 10)
          jQuery(".site-recent-projects.list a.loadMore").addClass('hidden');
      else
          jQuery(".site-recent-projects.list a.loadMore").removeClass('hidden');




  }




}
